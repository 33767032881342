type StyleValues = Record<string, string>;

interface Styles {
  global?: StyleValues;
  components?: Record<string, StyleValues>;
  font?: {
    url?: string;
    "font-family"?: string;
  };
}

/**
 * Applies global and component-specific styles as CSS variables to the document.
 *
 * This function takes a `styles` object containing global styles and component-specific
 * styles, then sets them as CSS variables (`--variable-name`) on the document root (`:root`).
 *
 */
export const applyStyles = (styles: Styles): void => {
  if (!styles) return;

  const root = document.documentElement;

  if (styles.global) {
    Object.entries(styles.global).forEach(([key, value]) => {
      root.style.setProperty(`--${key.replace(/_/g, "-")}`, value);
    });
  }

  if (styles.components) {
    Object.entries(styles.components).forEach(([component, styleObj]) => {
      const formattedComponent = component.replace(/_/g, "-");
      Object.entries(styleObj).forEach(([key, value]) => {
        root.style.setProperty(
          `--${formattedComponent}-${key.replace(/_/g, "-")}`,
          value
        );
      });
    });
  }

  // Handle Fonts
  if (styles.font) {
    if (styles.font?.url) {
      let fontLink = document.getElementById(
        "dynamic-font-link"
      ) as HTMLLinkElement;

      if (!fontLink) {
        fontLink = document.createElement("link");
        fontLink.id = "dynamic-font-link";
        fontLink.rel = "stylesheet";
        document.head.appendChild(fontLink);
      }

      fontLink.href = styles.font.url;
    }

    if (styles.font["font-family"]) {
      root.style.setProperty("--font-family", styles.font["font-family"]);
    }
  }
};
