import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Lottie from "lottie-react";
import animationData from "assets/animations/checkout_failed.json";
import { UUID } from "store/types/paymentLink";
import { getData } from "store/features/dataSlice";
import { applyStyles } from "utils/styles";

// Components
import HeaderContainer from "container/header";
import LoadingScreen from "views/screens/loading";

// Custom hook
import useIsMobile from "hooks/mobile";

import "./style.less";

// Function to convert CSS variable to [R, G, B, A]
const getLottieColors = (): {
  primaryColor: [number, number, number, number];
  primaryBackgroundColor: [number, number, number, number];
} => {
  const rootStyles = getComputedStyle(document.documentElement);

  const parseColor = (
    color: string,
    fallback: string,
  ): [number, number, number, number] => {
    color = color.trim();

    // HEX format (#RRGGBB or #RGB)
    if (color.startsWith("#")) {
      let hex = color.replace("#", "");
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((char) => char + char)
          .join(""); // Convert #RGB to #RRGGBB
      }
      const r = parseInt(hex.substring(0, 2), 16) / 255;
      const g = parseInt(hex.substring(2, 4), 16) / 255;
      const b = parseInt(hex.substring(4, 6), 16) / 255;
      return [r, g, b, 1]; // Default alpha to 1
    }

    // RGB(A) format
    const rgbMatch = color.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/,
    );
    if (rgbMatch) {
      return [
        parseInt(rgbMatch[1]) / 255, // Red (normalized)
        parseInt(rgbMatch[2]) / 255, // Green (normalized)
        parseInt(rgbMatch[3]) / 255, // Blue (normalized)
        rgbMatch[4] ? parseFloat(rgbMatch[4]) : 1, // Alpha (default 1)
      ];
    }

    return parseColor(fallback, ""); // If parsing fails, try fallback
  };

  return {
    primaryColor: parseColor(
      rootStyles.getPropertyValue("--error-icon-color"),
      "#FFFFFF",
    ),
    primaryBackgroundColor: parseColor(
      rootStyles.getPropertyValue("--error-icon-background"),
      "rgb(255, 37, 43)",
    ),
  };
};

const InvalidLinkScreen = () => {
  const { t } = useTranslation("views/screens/invalid-link");
  const [isLoading, setIsLoading] = useState(true);

  const isMobileView = useIsMobile(750);
  const dispatch = useAppDispatch();

  const { provider } = useAppSelector((state) => state.dataState.data);
  const navigate = useNavigate();

  // Selected language
  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language,
  );

  const providerDesignation = provider?.designation?.[currentLanguage];

  const uuid = localStorage.getItem("uuid");
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    setIsLoading(true);

    const controller = new AbortController();

    async function handleGetData(uuid: string) {
      setIsLoading(true);
      const resultAction = await dispatch(
        getData({ uuid: uuid, controller: controller }),
      );

      if (getData.rejected.match(resultAction)) {
        setIsLoading(false);
        return;
      }

      const { payload } = resultAction;

      // Check if there are styles in the "store" or "provider" object and apply them
      if (payload?.store?.styles) {
        applyStyles(payload?.store?.styles);
      } else if (payload?.provider?.styles) {
        applyStyles(payload?.provider?.styles);
      }
      setIsLoading(false);
    }

    if (uuid && uuid.match(UUID)) {
      handleGetData(uuid).then();
    } else {
      setIsLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, []);

  // Manipulate colors of lottie file
  const modifiedAnimationData = () => {
    if (isLoading || !animationData) {
      return;
    }

    const colors = getLottieColors();
    const newData = JSON.parse(JSON.stringify(animationData));

    if (newData.layers) {
      newData.layers.forEach((layer) => {
        if (layer.shapes) {
          layer.shapes.forEach((shape) => {
            if (shape.it) {
              shape.it.forEach((item) => {
                if (item.c && Array.isArray(item.c.k)) {
                  const [r, g, b] = item.c.k; // Extract RGB values
                  if (!(r === 1 && g === 1 && b === 1)) {
                    item.c.k = colors.primaryBackgroundColor;
                  } else {
                    item.c.k = colors.primaryColor;
                  }
                }
              });
            }
          });
        }
      });
    }

    return newData;
  };

  const openPopup = (url: string) => {
    const width = 800;
    const height = 650;

    // Get the position of the current window (helps with dual-screen setups)
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    // Get the screen dimensions (account for different methods in different browsers)
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      (window.screen && window.screen.width);
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      (window.screen && window.screen.height);

    // Calculate system zoom (needed for correct sizing and positioning)
    const systemZoom =
      screenWidth / (window.screen && window.screen.availWidth);

    // Calculate the center positions, adjusted for system zoom
    const left = (screenWidth - width) / 2 / systemZoom + dualScreenLeft;
    const top = (screenHeight - height) / 2 / systemZoom + dualScreenTop;

    // Open the window
    const newWindow = window.open(
      url,
      "popupWindow",
      `
      scrollbars=yes,
      width=${width / systemZoom},
      height=${height / systemZoom},
      top=${top},
      left=${left}
      `,
    );

    // Focus on the new window
    if (window.focus) newWindow.focus();
  };

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <div id="invalid-link-screen">
      <HeaderContainer className="header" />

      <div className="invalid-screen-container">
        <div className="content">
          <Lottie
            animationData={modifiedAnimationData()}
            loop={false}
            style={{
              height: isMobileView ? 120 : 150,
              width: isMobileView ? 120 : 150,
            }}
          />
          <div className="title">{t("title")}</div>
          <div className="description">{t("description")}</div>
        </div>
      </div>

      <div className="footer-container">
        <div className="footer">
          <div className="footer-content">
            <div className="powered-by">
              {providerDesignation && <strong>{providerDesignation}</strong>}{" "}
              {t("poweredBy")} REHEG
            </div>

            {uuid && accessToken && accessToken !== "null" && (
              <>
                <div className="seperator"></div>
                <div className="flex">
                  <div
                    className="links"
                    onClick={() =>
                      window.open(
                        `/${uuid}/terms?access_token=${accessToken}`,
                        "_blank",
                      )
                    }
                  >
                    {t("conditions")}
                  </div>
                  <div
                    className="links"
                    onClick={() =>
                      window.open(
                        `/${uuid}/privacy?access_token=${accessToken}`,
                        "_blank",
                      )
                    }
                  >
                    {t("privacy")}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="security-icon">
          <a
            href="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html"
            title="PCI DSS compliant | usd AG"
            target="_blank"
            rel="noreferrer noopener"
            onClick={(e) => {
              e.preventDefault(); // Prevent default behavior of opening in a new tab
              openPopup(
                "https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html",
              );
            }}
          >
            <img
              src="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/seal_65.png"
              alt="PCI DSS compliant | usd AG"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default InvalidLinkScreen;
