import { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { setLanguage } from "store/features/generalSlice";
import { AvailableLanguages } from "store/types/general";

// Import icons
import { ReactComponent as DropDownIcon } from "assets/icons/select-dropdown.svg";

import "./style.less";

const StyledSelectBox = styled.div`
  color: var(--language-select-color, var(--primary-color, #3c3d3d));
  background-color: var(--language-select-background, #fff);
  border: var(--language-select-border, 1px solid #847d7d);
  border-radius: var(
    --language-select-border-radius,
    var(--border-radius, 8px)
  );
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--language-select-hover-background, #fff);
    color: var(--language-select-hover-color, var(--primary-color, #3c3d3d));
  }

  &.open {
    color: var(--language-select-color, var(--primary-color, #3c3d3d));
    background-color: var(--language-select-background, #fff);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: var(--language-select-hover-border, 1px solid #847d7d);
  }
`;

const StyledOptionList = styled.div`
  color: var(--language-select-color, var(--primary-color, #3c3d3d));
  background-color: var(--language-select-background, #fff);
`;

const StyledOptionItem = styled.div`
  border-bottom: var(--language-select-border, 1px solid #847d7d);
  border-right: var(--language-select-border, 1px solid #847d7d);
  border-left: var(--language-select-border, 1px solid #847d7d);
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--language-select-hover-item-background, #0a0a0a);
    color: var(--language-select-hover-item-color, #fff);
  }
`;

// Helper function to import icons dynamically
const loadLanguageIcon = async (language) => {
  try {
    const icon = await import(`assets/icons/languages/${language}.svg`);

    return icon.default;
  } catch (error) {
    return null;
  }
};

interface LanguageComponentProps {
  onLanguageChange?: Function;
}

const LanguageSelectComponent = ({
  onLanguageChange,
}: LanguageComponentProps) => {
  const { t } = useTranslation("components/language");
  const dispatch = useAppDispatch();
  const activeLanguage = useAppSelector(
    (state) => state.generalState.general.language,
  );

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(activeLanguage);
  const [icons, setIcons] = useState({});
  const selectRef = useRef<HTMLDivElement>(null); // For outside click detection

  useEffect(() => {
    const fetchIcons = async () => {
      const iconPromises = Object.keys(AvailableLanguages).map(async (key) => {
        const language =
          AvailableLanguages[key as keyof typeof AvailableLanguages];
        const IconUrl = await loadLanguageIcon(language);
        return { [language]: IconUrl };
      });
      const resolvedIcons = await Promise.all(iconPromises);
      const iconsMap = resolvedIcons.reduce(
        (acc, icon) => ({ ...acc, ...icon }),
        {},
      );
      setIcons(iconsMap);
    };

    fetchIcons();
  }, []);

  // Handle outside click to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setIsOpen(false);
    dispatch(setLanguage(language));
    if (onLanguageChange) {
      onLanguageChange();
    }
  };

  const filteredLanguages = Object.keys(AvailableLanguages).filter((key) => {
    const language = AvailableLanguages[key as keyof typeof AvailableLanguages];
    return language !== selectedLanguage; // Exclude the selected language
  });

  const languages = filteredLanguages.map((key) => {
    const language = AvailableLanguages[key as keyof typeof AvailableLanguages];
    const IconUrl = icons[language];

    return (
      <StyledOptionItem
        key={crypto.randomUUID()}
        className={`option-item ${
          selectedLanguage === language ? "active" : ""
        }`}
        onClick={() => handleLanguageSelect(language)}
      >
        {IconUrl && <img src={IconUrl} alt={language} className="flag-icon" />}
        <div className="value">{t(key)}</div>
      </StyledOptionItem>
    );
  });

  return (
    <div id="language-select-component" ref={selectRef}>
      <StyledSelectBox
        className={`select-box ${isOpen ? "open" : ""}`}
        onClick={toggleDropdown}
      >
        {icons[selectedLanguage] && (
          <img
            src={icons[selectedLanguage]}
            alt={selectedLanguage}
            className="flag-icon"
          />
        )}

        <div className="selected-value">
          {t(
            Object.keys(AvailableLanguages).find(
              (key) =>
                AvailableLanguages[key as keyof typeof AvailableLanguages] ===
                selectedLanguage,
            ),
          )}

          <DropDownIcon className={`dropdown-icon ${isOpen ? "open" : ""}`} />
        </div>
      </StyledSelectBox>
      {isOpen && (
        <StyledOptionList className="options-list">
          {languages}
        </StyledOptionList>
      )}
    </div>
  );
};

export default LanguageSelectComponent;
