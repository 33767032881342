import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { styled } from "styled-components";

// Import icons
import { ReactComponent as DropDownIcon } from "assets/icons/select-dropdown.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/error-field.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/check.svg";

import "./style.less";

const StyledSelect = styled.div`
  background-color: var(--input-background, #fff);
  color: var(--input-color, var(--primary-color, #3c3d3d));
  border: var(--input-border, 1px solid #e6ebf1);
  border-radius: var(--input-border-radius, var(--border-radius, 8px));

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: var(--input-hover-border, 1px solid #d9dadc);
  }
`;

const StyledOptionList = styled.div`
  background-color: var(--input-background, #fff);
  color: var(--input-color, var(--primary-color, #3c3d3d));
`;

const StyledOptionItem = styled.div`
  border-bottom: var(--input-border, 1px solid #e6ebf1);
  border-right: var(--input-border, 1px solid #e6ebf1);
  border-left: var(--input-border, 1px solid #e6ebf1);

  &:hover {
    background-color: var(--input-hover-background, #0a0a0a);
    color: var(--input-hover-color, #fff);
  }
`;

const StyledOptionalInfo = styled.div`
  color: var(--input-color, var(--primary-color, #3c3d3d));
`;

const StyledInput = styled.input`
  color: var(--input-color, var(--primary-color, #3c3d3d));

  &::placeholder {
    color: var(--input-placeholder, #919191);
  }
`;

const StyledLabel = styled.div`
  color: var(--input-label-color, var(--primary-color, #3c3d3d));
`;

interface option {
  key: string;
  value: string;
}

interface SelectComponentProps {
  className?: string; // Additional class names to apply to the select component
  options: option[]; // Array of options to display in the dropdown
  selected: option; // The selected option
  label: string; // The label for the input field
  isRequired?: boolean; // Whether the input field is required
  errorMessage?: string; // The error message to display if the input field is invalid
  isValid?: boolean; // Whether the selected value is valid
  isFullWidth?: boolean; // Whether the button should take up the full width of its container
  onChange: Function; // The function to call when an option is selected
}

const SelectComponent = ({
  className,
  options,
  selected = { key: "", value: "" },
  label,
  isRequired = false,
  isValid = false,
  errorMessage,
  isFullWidth,
  onChange,
}: SelectComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selected);
  const [inputValue, setInputValue] = useState(""); // Empty input when dropdown opens
  const selectRef = useRef<HTMLDivElement>(null); // For outside click detection

  const selectClassNames = classNames(
    {
      "full-width": isFullWidth,
    },
    className,
  );

  const { t } = useTranslation("components/select");

  // Handle outside click to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);

    // Reset input value to empty when the dropdown is opened
    if (!isOpen) {
      setInputValue(""); // Clear input when opening the dropdown
    }
  };

  const onSelectChange = (key: string, value: string) => {
    setSelectedOption({ key, value });
    setIsOpen(false);
    onChange && onChange({ key, value });
  };

  // Filter options based on the input value
  const filteredOptions = options.filter(({ value }) =>
    value.toLowerCase().includes(inputValue.toLowerCase()),
  );

  const selectableOptions = filteredOptions.map(({ key, value }) => (
    <StyledOptionItem
      key={key} // Use key if it's unique
      className={`option-item ${selectedOption.key === key ? "active" : ""}`}
      onClick={() => onSelectChange(key, value)}
    >
      <div className="value">{value}</div>
    </StyledOptionItem>
  ));

  return (
    <div id="select-component" className={selectClassNames} ref={selectRef}>
      <div className="flex items-center">
        <StyledLabel className="label">{label}</StyledLabel>
        {!isRequired && (
          <StyledOptionalInfo className="optional-info">{`(${t(
            "optional",
          )})`}</StyledOptionalInfo>
        )}
      </div>

      <div className="select-container">
        <StyledSelect
          className={`select-box ${isOpen ? "open" : ""}`}
          onClick={toggleDropdown}
        >
          {/* When the dropdown is open, show an input field with placeholder */}
          {isOpen ? (
            <StyledInput
              type="text"
              className="select-input-field"
              placeholder={t("search")} // Placeholder text
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)} // Filter options as user types
              autoFocus
            />
          ) : (
            <div className="selected-value">
              {selectedOption.value} <DropDownIcon className="dropdown-icon" />
            </div>
          )}

          {errorMessage && <ErrorIcon className="error-icon" />}
        </StyledSelect>

        {/* Show the options list if dropdown is open */}
        {isOpen && (
          <StyledOptionList className="options-list">
            {selectableOptions}
          </StyledOptionList>
        )}
      </div>

      <div className="error-message">{errorMessage}</div>
    </div>
  );
};

export default SelectComponent;
