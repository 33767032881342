import axios from "axios";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { PaymentLinkProps, Status } from "../types/paymentLink";

interface PaymentLinkState {
  paymentLink: PaymentLinkProps;
}

const initialState: PaymentLinkState = {
  paymentLink: {
    status: Status.Idle,
    url: null,
    storeId: null,
    entityId: null,
    reference: null,
    currency: null,
    amount: null,
  },
};

export const createPaymentLink = createAsyncThunk(
  "paymentLink/createPaymentLink",
  async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_PAY_BY_LINK_SERVICE_URL}/payment-links/`,
      {
        store: "default_ecom",
        amount: 0.0,
        currency: "EUR",
        external_id: "2024",
        language: "de",
        return_url: "https://www.reheg.com",
        hide_cvc: false,
        pre_authorize: true,
        tokenize: true,
        payment_methods: [
          "google_pay",
          "apple_pay",
          "credit_card",
          "bank_transfer",
          "pay_by_bank",
        ],
        payment_items: [],
        payment_messages: [
          {
            authorized: {
              title: {
                en: "The payment was successful.",
                de: "Die Zahlung war erfolgreich.",
                it: "Il pagamento è andato a buon fine.",
              },
              description: {
                en: "Thank you, your booking is confirmed.",
                de: "Vielen Dank, hiermit ist Ihre Buchung bestätigt.",
                it: "Grazie, la tua prenotazione è confermata.",
              },
            },
          },
          {
            refused: {
              title: {
                en: "The payment was not successful.",
                de: "Die Zahlung war nicht erfolgreich.",
                it: "Il pagamento non è andato a buon fine.",
              },
              description: {
                en: "Unfortunately, your payment was not successful. Please try again.",
                de: "Ihre Zahlung war leider nicht erfolgreich. Bitte versuchen Sie es erneut.",
                it: "Purtroppo il pagamento non è andato a buon fine. Vi preghiamo di riprovare.",
              },
            },
          },
          {
            selection: {
              title: {
                en: "Your payment for the Hotel Alter Wirt in Grünwald.",
                de: "Deine Zahlung für das Hotel Alter Wirt in Grünwald.",
                it: "Il tuo pagamento per l'Hotel Alter Wirt a Grünwald.",
              },
              description: {
                en: "Please select the desired payment method.",
                de: "Bitte wählen Sie die gewünschte Zahlungsmethode aus.",
                it: "Si prega di selezionare il metodo di pagamento desiderato.",
              },
            },
          },
        ],
        metadata: [
          { key: "test1", value: "value_1" },
          { key: "test2", value: "value_2" },
        ],
        application: {
          name: "ASA HOTEL",
          version: "22.20.1",
          device: {
            name: "Windows",
            version: "11",
          },
        },
      },
      {
        auth: {
          username: process.env.REACT_APP_DEMO_USER_USERNAME as string,
          password: process.env.REACT_APP_DEMO_USER_PASSWORD as string,
        },
      },
    );
    return response.data;
  },
);

export const paymentLinkSlice = createSlice({
  initialState,
  name: "paymentLink",
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createPaymentLink.pending, (state) => {
        state.paymentLink.status = Status.Loading;
      })
      .addCase(createPaymentLink.fulfilled, (state, action) => {
        // @formatter:off
        state.paymentLink.status = Status.Succeeded;
        state.paymentLink.url = action.payload.url;
        // @formatter:on
      })
      .addCase(createPaymentLink.rejected, (state) => {
        state.paymentLink.status = Status.Failed;
      });
  },
});

export default paymentLinkSlice.reducer;
