import { useState } from "react";
import { styled } from "styled-components";

// Import icons
import { ReactComponent as CheckIcon } from "assets/icons/checkbox.svg";

import "./style.less";

const StyledCheckbox = styled.input`
  border-radius: var(--checkbox-border-radius, var(--border-radius, 3px));
  border: var(--checkbox-border, 1px solid #e6ebf1);
  background-color: var(--checkbox-background, #fff);
  transition:
    border-color 0.2s ease,
    background-color 0.2s ease;

  &:hover {
    border: var(--checkbox-hover-border, 1px solid #e6ebf1);
  }

  &:checked {
    transition: background-color 0.2s ease;
    background-color: var(
      --checkbox-checked-background,
      var(--checkbox-background, #0a0a0a)
    );
  }
`;

const StyledLabel = styled.label`
  color: var(--checkbox-color, var(--primary-color, #3c3d3d));
  margin: var(--checkbox-label-margin-top, 0px);

  a {
    color: var(--checkbox-color, var(--primary-color, #3c3d3d));
  }
`;

const StyledCheckedIcon = styled.div`
  transition: color 0.2s ease;
  color: var(--checkbox-checked-color, var(--checkbox-color, #fff));
`;

const StyledCheckboxContainer = styled.div``;

interface CheckboxComponentProps {
  checked: boolean; // Whether the checkbox is initially checked
  onBlur?: Function; // The function to call when the checkbox loses focus
  onChange?: Function; // The function to call when the checkbox is checked or unchecked
  label?: string; // The label for the checkbox
  name?: string; // The name of the checkbox
  setInnerHtml?: boolean; // Whether to set the inner HTML of the checkbox
  errorMessage?: string; // The error message to display if the checkbox is invalid
}

const CheckboxComponent = ({
  checked,
  label,
  name,
  onChange,
  onBlur,
  setInnerHtml,
  errorMessage,
}: CheckboxComponentProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    onChange && onChange(newCheckedState);
  };

  return (
    <div id="checkbox-component">
      <div className="flex items-center">
        <StyledCheckboxContainer className="checkbox-container">
          <StyledCheckbox
            className="checkbox"
            type="checkbox"
            name={name}
            checked={isChecked}
            onBlur={onBlur}
            onChange={handleChange}
          ></StyledCheckbox>
          {isChecked && (
            <StyledCheckedIcon className="check-icon-container">
              {<CheckIcon className="check-icon" />}
            </StyledCheckedIcon>
          )}
        </StyledCheckboxContainer>
        <StyledLabel
          className="label"
          htmlFor="checkbox"
          onClick={handleChange}
        >
          {!setInnerHtml ? (
            label
          ) : (
            <div dangerouslySetInnerHTML={{ __html: label }} />
          )}
        </StyledLabel>
      </div>
      <div className="error-message">{errorMessage}</div>
    </div>
  );
};

export default CheckboxComponent;
