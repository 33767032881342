import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";

import { getPaymentMethods } from "store/features/onlinePaymentSlice";

// Components
import LanguageSelectComponent from "components/language-select";
import ButtonComponent from "components/buttons";

// Import icons
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";

import useNavigateWithQueryParams from "hooks/navigate";

import "./style.less";

interface HeaderContainerProps {
  className?: string;
}

const HeaderContainer = ({ className }: HeaderContainerProps) => {
  const { t } = useTranslation("container/header");

  const location = useLocation();
  const { uuid } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navigateWithQueryParams = useNavigateWithQueryParams();

  // Retrieve data
  const { returnUrl, paymentMethods } = useAppSelector(
    (state) => state.dataState.data,
  );

  let buttonText = t("return");

  if (
    (location.pathname === `/${uuid}/cards` && paymentMethods?.length > 1) ||
    (location.pathname === `/${uuid}/bank-transfer` &&
      paymentMethods?.length > 1)
  ) {
    buttonText = t("paymentMethods");
  }

  const onLanguageChange = () => {
    // Get adyen payment methods
    dispatch(getPaymentMethods());
  };

  const handleReturn = () => {
    const isBankTransferScreen = location.pathname.startsWith(
      `/${uuid}/bank-transfer`,
    );
    const isBankInformationScreen = location.pathname.startsWith(
      `/${uuid}/bank-transfer/bank-information`,
    );

    const isRequestBankDataScreen = location.pathname.startsWith(
      `/${uuid}/bank-transfer/request-bank-data`,
    );

    const isCardsScreen = location.pathname.startsWith(`/${uuid}/cards`);

    if (
      location.pathname === `/${uuid}` ||
      (isCardsScreen && paymentMethods?.length === 1) ||
      (isBankTransferScreen && paymentMethods?.length === 1)
    ) {
      returnUrl && window.location.replace(returnUrl);
    } else {
      if (isBankInformationScreen || isRequestBankDataScreen) {
        navigateWithQueryParams(`/${uuid}/bank-transfer`);
      } else if (isBankTransferScreen || isCardsScreen) {
        navigateWithQueryParams(`/${uuid}`);
      } else {
        navigate(-1);
      }
    }
  };

  return (
    <div id="header-container" className={className}>
      {(location.pathname.startsWith(`/${uuid}/bank-transfer`) &&
        paymentMethods.length > 1) ||
      (location.pathname.startsWith(`/${uuid}/cards`) &&
        paymentMethods.length > 1) ||
      (returnUrl && paymentMethods.length === 1) ||
      (returnUrl && location.pathname === `/${uuid}`) ? (
        <ButtonComponent
          componentName="return-button"
          className="return-button"
          isPrimary={false}
          text={buttonText}
          icon={<ArrowLeftIcon />}
          onClick={() => handleReturn()}
        />
      ) : (
        <div />
      )}
      <LanguageSelectComponent onLanguageChange={onLanguageChange} />
    </div>
  );
};

export default HeaderContainer;
