import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { useParams } from "react-router-dom";
import formatCurrency from "utils/currency";
import getPaymentMethodBrand from "utils/payment-methods";

// Components
import ButtonComponent from "components/buttons";
import HeaderContainer from "container/header";

import Lottie from "lottie-react";
import animationData from "assets/animations/checkout.json";

// Custom hook
import useIsMobile from "hooks/mobile";
import useNavigateWithQueryParams from "hooks/navigate";

// Icons
import { ReactComponent as DateIcon } from "assets/icons/date.svg";

import "./style.less";

// Function to convert CSS variable to [R, G, B, A]
const getLottieColors = (): {
  primaryColor: [number, number, number, number];
  primaryBackgroundColor: [number, number, number, number];
} => {
  const rootStyles = getComputedStyle(document.documentElement);

  const parseColor = (
    color: string,
    fallback: string,
  ): [number, number, number, number] => {
    color = color.trim();

    // HEX format (#RRGGBB or #RGB)
    if (color.startsWith("#")) {
      let hex = color.replace("#", "");
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((char) => char + char)
          .join(""); // Convert #RGB to #RRGGBB
      }
      const r = parseInt(hex.substring(0, 2), 16) / 255;
      const g = parseInt(hex.substring(2, 4), 16) / 255;
      const b = parseInt(hex.substring(4, 6), 16) / 255;
      return [r, g, b, 1]; // Default alpha to 1
    }

    // RGB(A) format
    const rgbMatch = color.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/,
    );
    if (rgbMatch) {
      return [
        parseInt(rgbMatch[1]) / 255, // Red (normalized)
        parseInt(rgbMatch[2]) / 255, // Green (normalized)
        parseInt(rgbMatch[3]) / 255, // Blue (normalized)
        rgbMatch[4] ? parseFloat(rgbMatch[4]) : 1, // Alpha (default 1)
      ];
    }

    return parseColor(fallback, ""); // If parsing fails, try fallback
  };

  return {
    primaryColor: parseColor(
      rootStyles.getPropertyValue("--success-icon-color"),
      "#FFFFFF",
    ),
    primaryBackgroundColor: parseColor(
      rootStyles.getPropertyValue("--success-icon-background"),
      "rgb(58, 201, 104)",
    ),
  };
};

const AuthorizedPaymentScreen = () => {
  const { t } = useTranslation("views/screens/payment/authorized");

  const isMobileView = useIsMobile(750);
  const { uuid } = useParams();
  const navigateWithQueryParams = useNavigateWithQueryParams();

  // Retrieve data
  const {
    amount,
    currency,
    paymentItems,
    returnUrl,
    paymentMessages,
    tokenize,
    provider,
  } = useAppSelector((state) => state.dataState.data);

  const { paymentMethod, pspReference, creditCard } = useAppSelector(
    (state) => state.onlinePaymentState.onlinePayment,
  );

  // Selected language
  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language,
  );

  const providerDesignation = provider?.designation?.[currentLanguage];

  const messages = paymentMessages.find((obj) =>
    obj.hasOwnProperty("authorized"),
  );
  let title = messages?.authorized?.title?.[currentLanguage];

  if (tokenize && amount === 0) {
    title = t("tokenisationSuccessful");
  }

  const description = messages?.authorized?.description?.[currentLanguage];

  // Modify the animation data
  const modifiedAnimationData = useMemo(() => {
    const colors = getLottieColors();
    const newData = JSON.parse(JSON.stringify(animationData)); // Deep copy

    if (newData.layers) {
      newData.layers.forEach((layer) => {
        if (layer.shapes) {
          layer.shapes.forEach((shape) => {
            if (shape.it) {
              shape.it.forEach((item) => {
                if (item.c && Array.isArray(item.c.k)) {
                  const [r, g, b] = item.c.k; // Extract RGB values
                  if (!(r === 1 && g === 1 && b === 1)) {
                    item.c.k = colors.primaryBackgroundColor;
                  } else {
                    item.c.k = colors.primaryColor;
                  }
                }
              });
            }
          });
        }
      });
    }

    return newData;
  }, []);

  const openPopup = (url: string) => {
    const width = 800;
    const height = 650;

    // Get the position of the current window (helps with dual-screen setups)
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    // Get the screen dimensions (account for different methods in different browsers)
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      (window.screen && window.screen.width);
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      (window.screen && window.screen.height);

    // Calculate system zoom (needed for correct sizing and positioning)
    const systemZoom =
      screenWidth / (window.screen && window.screen.availWidth);

    // Calculate the center positions, adjusted for system zoom
    const left = (screenWidth - width) / 2 / systemZoom + dualScreenLeft;
    const top = (screenHeight - height) / 2 / systemZoom + dualScreenTop;

    // Open the window
    const newWindow = window.open(
      url,
      "popupWindow",
      `
      scrollbars=yes,
      width=${width / systemZoom},
      height=${height / systemZoom},
      top=${top},
      left=${left}
      `,
    );

    // Focus on the new window
    if (window.focus) newWindow.focus();
  };

  return (
    <div id="authorized-payment-screen">
      <div className="authorized-screen-container">
        <HeaderContainer className="header" />

        <div className="content">
          <Lottie
            animationData={modifiedAnimationData}
            loop={false}
            style={{
              height: isMobileView ? 120 : 150,
              width: isMobileView ? 120 : 150,
            }}
          />

          <div className="title">{title}</div>

          {!(tokenize && amount === 0) && (
            <div className="description">{description}</div>
          )}

          <div className="box">
            {paymentItems?.map((item) => {
              const title = item?.title?.[currentLanguage];
              const description = item?.description?.[currentLanguage];

              const hasDatePeriod = item?.period?.start && item?.period?.end;

              return (
                <div className="header-container" key={crypto.randomUUID()}>
                  <div className="header">
                    {!isMobileView && item?.image_base64 && (
                      <img
                        className="image"
                        src={item?.image_base64}
                        alt={title}
                      />
                    )}

                    <div className="box-content">
                      <div className="flex flex-column">
                        <div className="box-header">
                          <div className="title">{title}</div>
                          <div className="price">
                            {formatCurrency(
                              item?.amount,
                              currentLanguage,
                              currency?.symbol,
                            )}
                          </div>
                        </div>
                        {!isMobileView ? (
                          <div className="description">{description}</div>
                        ) : (
                          <div className="description-container">
                            {item?.image_base64 && (
                              <img
                                className="image"
                                src={item?.image_base64}
                                alt={title}
                              />
                            )}
                            <div className="description">{description}</div>
                          </div>
                        )}
                      </div>

                      {item?.period && (
                        <div className="date">
                          <DateIcon className="date-icon" />
                          {!hasDatePeriod && item?.period?.start}
                          {hasDatePeriod &&
                            item?.period?.start + " - " + item?.period?.end}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="seperator"></div>

            <div className="content">
              {paymentMethod === "credit_card" && creditCard?.brand_name && (
                <div className="item">
                  <div className="label">{t("paymentMethod")}</div>
                  <div className="value">
                    {t(`paymentMethods.creditCard`)} -{" "}
                    {getPaymentMethodBrand(creditCard.brand_name)}
                  </div>
                </div>
              )}

              {creditCard?.brand_name?.includes("googlepay") && (
                <div className="item">
                  <div className="label">{t("paymentMethod")}</div>
                  <div className="value">{t(`paymentMethods.googlePay`)}</div>
                </div>
              )}

              {creditCard?.brand_name?.includes("applepay") && (
                <div className="item">
                  <div className="label">{t("paymentMethod")}</div>
                  <div className="value">{t(`paymentMethods.applepay`)}</div>
                </div>
              )}

              <div className="item">
                <div className="label">{t("paymentReference")}</div>
                <div className="value">{pspReference ? pspReference : "-"}</div>
              </div>
            </div>

            <div className="seperator"></div>

            <div className="footer">
              <div className="item">
                <div className="label">{t("totalPrice")}</div>
                <div className="value">
                  {formatCurrency(amount, currentLanguage, currency?.symbol)}
                </div>
              </div>
            </div>
          </div>

          {returnUrl && (
            <ButtonComponent
              componentName="return-home-button"
              text={t("returnHome")}
              isFullWidth={true}
              onClick={() => returnUrl && window.location.replace(returnUrl)}
              hasBoxShadow={true}
            />
          )}
        </div>
      </div>

      <div className="footer-container">
        <div className="footer">
          <div className="footer-content">
            <div className="powered-by">
              {providerDesignation && <strong>{providerDesignation}</strong>}{" "}
              {t("poweredBy")} REHEG
            </div>
            <div className="seperator"></div>
            <div className="flex">
              <div
                className="links"
                onClick={() => navigateWithQueryParams(`/${uuid}/terms`, true)}
              >
                {t("conditions")}
              </div>
              <div
                className="links"
                onClick={() =>
                  navigateWithQueryParams(`/${uuid}/privacy`, true)
                }
              >
                {t("privacy")}
              </div>
            </div>
          </div>
        </div>

        <div className="security-icon">
          <a
            href="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html"
            title="PCI DSS compliant | usd AG"
            target="_blank"
            rel="noreferrer noopener"
            onClick={(e) => {
              e.preventDefault(); // Prevent default behavior of opening in a new tab
              openPopup(
                "https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html",
              );
            }}
          >
            <img
              src="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/seal_65.png"
              alt="PCI DSS compliant | usd AG"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AuthorizedPaymentScreen;
