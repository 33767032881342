import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { useParams } from "react-router-dom";
import formatCurrency from "utils/currency";
import getPaymentMethodBrand from "utils/payment-methods";

// Components
import ButtonComponent from "components/buttons";
import HeaderContainer from "container/header";

import Lottie from "lottie-react";
import animationData from "assets/animations/checkout_failed.json";

// Custom hook
import useIsMobile from "hooks/mobile";
import useNavigateWithQueryParams from "hooks/navigate";

// Icons
import { ReactComponent as DateIcon } from "assets/icons/date.svg";

import "./style.less";

// Function to convert CSS variable to [R, G, B, A]
const getLottieColors = (): {
  primaryColor: [number, number, number, number];
  primaryBackgroundColor: [number, number, number, number];
} => {
  const rootStyles = getComputedStyle(document.documentElement);

  const parseColor = (
    color: string,
    fallback: string,
  ): [number, number, number, number] => {
    color = color.trim();

    // HEX format (#RRGGBB or #RGB)
    if (color.startsWith("#")) {
      let hex = color.replace("#", "");
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((char) => char + char)
          .join(""); // Convert #RGB to #RRGGBB
      }
      const r = parseInt(hex.substring(0, 2), 16) / 255;
      const g = parseInt(hex.substring(2, 4), 16) / 255;
      const b = parseInt(hex.substring(4, 6), 16) / 255;
      return [r, g, b, 1]; // Default alpha to 1
    }

    // RGB(A) format
    const rgbMatch = color.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/,
    );
    if (rgbMatch) {
      return [
        parseInt(rgbMatch[1]) / 255, // Red (normalized)
        parseInt(rgbMatch[2]) / 255, // Green (normalized)
        parseInt(rgbMatch[3]) / 255, // Blue (normalized)
        rgbMatch[4] ? parseFloat(rgbMatch[4]) : 1, // Alpha (default 1)
      ];
    }

    return parseColor(fallback, ""); // If parsing fails, try fallback
  };

  return {
    primaryColor: parseColor(
      rootStyles.getPropertyValue("--error-icon-color"),
      "#FFFFFF",
    ),
    primaryBackgroundColor: parseColor(
      rootStyles.getPropertyValue("--error-icon-background"),
      "rgb(255, 37, 43)",
    ),
  };
};

const RefusedPaymentScreen = () => {
  const { t } = useTranslation("views/screens/payment/refused");

  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { uuid } = useParams();

  const isMobileView = useIsMobile(750);

  // Retrieve data
  const { amount, currency, paymentItems, paymentMessages, provider } =
    useAppSelector((state) => state.dataState.data);

  const { paymentMethod, pspReference, creditCard } = useAppSelector(
    (state) => state.onlinePaymentState.onlinePayment,
  );

  // Selected language
  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language,
  );

  const providerDesignation = provider?.designation?.[currentLanguage];

  const messages = paymentMessages.find((obj) => obj.hasOwnProperty("refused"));
  const title = messages?.refused?.title?.[currentLanguage];
  const description = messages?.refused?.description?.[currentLanguage];

  // Modify the animation data
  const modifiedAnimationData = useMemo(() => {
    const colors = getLottieColors();
    const newData = JSON.parse(JSON.stringify(animationData)); // Deep copy

    if (newData.layers) {
      newData.layers.forEach((layer) => {
        if (layer.shapes) {
          layer.shapes.forEach((shape) => {
            if (shape.it) {
              shape.it.forEach((item) => {
                if (item.c && Array.isArray(item.c.k)) {
                  const [r, g, b] = item.c.k; // Extract RGB values
                  if (!(r === 1 && g === 1 && b === 1)) {
                    item.c.k = colors.primaryBackgroundColor;
                  } else {
                    item.c.k = colors.primaryColor;
                  }
                }
              });
            }
          });
        }
      });
    }

    return newData;
  }, []);

  return (
    <div id="refused-payment-screen">
      <div className="refused-screen-container">
        <HeaderContainer className="header" />

        <div className="content">
          <Lottie
            animationData={modifiedAnimationData}
            loop={false}
            style={{
              height: isMobileView ? 120 : 150,
              width: isMobileView ? 120 : 150,
            }}
          />
          <div className="title">{title}</div>
          <div className="description">{description}</div>
          <div className="box">
            {paymentItems?.map((item) => {
              const title = item?.title?.[currentLanguage];
              const description = item?.description?.[currentLanguage];

              const hasDatePeriod = item?.period?.start && item?.period?.end;

              return (
                <div className="header-container" key={crypto.randomUUID()}>
                  <div className="header">
                    {!isMobileView && item?.image_base64 && (
                      <img
                        className="image"
                        src={item?.image_base64}
                        alt={title}
                      />
                    )}

                    <div className="box-content">
                      <div className="flex flex-column">
                        <div className="box-header">
                          <div className="title">{title}</div>
                          <div className="price">
                            {formatCurrency(
                              item?.amount,
                              currentLanguage,
                              currency?.symbol,
                            )}
                          </div>
                        </div>
                        {!isMobileView ? (
                          <div className="description">{description}</div>
                        ) : (
                          <div className="description-container">
                            {item?.image_base64 && (
                              <img
                                className="image"
                                src={item?.image_base64}
                                alt={title}
                              />
                            )}
                            <div className="description">{description}</div>
                          </div>
                        )}
                      </div>

                      {item?.period && (
                        <div className="date">
                          <DateIcon className="date-icon" />
                          {!hasDatePeriod && item?.period?.start}
                          {hasDatePeriod &&
                            item?.period?.start + " - " + item?.period?.end}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="seperator"></div>

            <div className="content">
              {paymentMethod === "credit_card" && creditCard?.brand_name && (
                <div className="item">
                  <div className="label">{t("paymentMethod")}</div>
                  <div className="value">
                    {t(`paymentMethods.creditCard`)} -{" "}
                    {getPaymentMethodBrand(creditCard.brand_name)}
                  </div>
                </div>
              )}

              {creditCard?.brand_name?.includes("googlepay") && (
                <div className="item">
                  <div className="label">{t("paymentMethod")}</div>
                  <div className="value">{t(`paymentMethods.googlePay`)}</div>
                </div>
              )}

              {creditCard?.brand_name?.includes("applepay") && (
                <div className="item">
                  <div className="label">{t("paymentMethod")}</div>
                  <div className="value">{t(`paymentMethods.applepay`)}</div>
                </div>
              )}

              <div className="item">
                <div className="label">{t("paymentReference")}</div>
                <div className="value">{pspReference ? pspReference : "-"}</div>
              </div>
            </div>

            <div className="seperator"></div>

            <div className="footer">
              <div className="item">
                <div className="label">{t("totalPrice")}</div>
                <div className="value">
                  {formatCurrency(amount, currentLanguage, currency?.symbol)}
                </div>
              </div>
            </div>
          </div>
          <ButtonComponent
            text={t("tryAgain")}
            componentName="retry-button"
            isFullWidth={true}
            onClick={() => navigateWithQueryParams(`/${uuid}`)}
            hasBoxShadow={true}
          />
        </div>
      </div>

      <div className="footer-container">
        <div className="footer">
          <div className="footer-content">
            <div className="powered-by">
              {providerDesignation && <strong>{providerDesignation}</strong>}{" "}
              {t("poweredBy")} REHEG
            </div>
            <div className="seperator"></div>
            <div className="flex">
              <div
                className="links"
                onClick={() => navigateWithQueryParams(`/${uuid}/terms`, true)}
              >
                {t("conditions")}
              </div>
              <div
                className="links"
                onClick={() =>
                  navigateWithQueryParams(`/${uuid}/privacy`, true)
                }
              >
                {t("privacy")}
              </div>
            </div>
          </div>
        </div>

        <div className="security-icon">
          <a
            href="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html"
            title="PCI DSS compliant | usd AG"
            target="_blank"
            rel="noreferrer noopener"
            onClick={(e) => {
              e.preventDefault(); // Prevent default behavior of opening in a new tab
              openPopup(
                "https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html",
              );
            }}
          >
            <img
              src="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/seal_65.png"
              alt="PCI DSS compliant | usd AG"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default RefusedPaymentScreen;
