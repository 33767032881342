import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AvailableLanguages, GeneralProps } from "../types/general";
import i18n from "i18n/configuration";
import axios from "axios";

interface GeneralState {
  general: GeneralProps; // Add accessToken as optional
}

const initialState: GeneralState = {
  general: {
    language: AvailableLanguages.English,
    accessToken: undefined,
    uuid: undefined,
    country: undefined,
  },
};

export const getUserCountry = createAsyncThunk(
  "general/getUserCountry",
  async () => {
    const response = await axios.get("https://api.country.is/");

    if (!response?.data) {
      return null;
    }

    return response.data;
  }
);

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<AvailableLanguages>) => {
      state.general.language = action.payload;
      if (i18n.language !== state.general.language) {
        i18n.changeLanguage(state.general.language).then();
      }
    },
    setAccessToken: (state, action: PayloadAction<string | undefined>) => {
      state.general.accessToken = action.payload;
      localStorage.setItem("accessToken", action.payload);
    },
    setUUID: (state, action: PayloadAction<string | undefined>) => {
      state.general.uuid = action.payload;
      localStorage.setItem("uuid", action.payload); // Save to storage to keep on page refresh
    },
  },
  extraReducers(builder) {
    builder.addCase(getUserCountry.fulfilled, (state, action) => {
      // @formatter:off
      state.general.country = action.payload.country;
      // @formatter:on
    });
  },
});

export default generalSlice.reducer;

// Export the action creators
export const { setLanguage, setAccessToken, setUUID } = generalSlice.actions;
