import React, { useState } from "react";
import "./style.less";
import classNames from "classnames";
import styled from "styled-components";

interface ButtonProps {
  componentName?: string;
  isPrimary?: boolean;
}

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !["isPrimary", "componentName"].includes(prop),
})<ButtonProps>`
  background-color: ${({ componentName, isPrimary }) =>
    `var(--${componentName}-background, ${isPrimary ? "#0a0a0a" : "#e6ebf1"})`};

  color: ${({ componentName, isPrimary }) => {
    if (componentName === "return-button") {
      return `var(--${componentName}-color, ${isPrimary ? "#ffffff" : "var(--primary-color, '#3C3D3D')"})`;
    }
    return `var(--${componentName}-color, ${isPrimary ? "#ffffff" : "#3C3D3D"})`;
  }};

  border: ${({ componentName, isPrimary }) =>
    `var(--${componentName}-border, ${
      isPrimary ? "1px solid #847d7d" : "1px solid #e6ebf1"
    })`};

  border-radius: ${({ componentName }) =>
    `var(--${componentName}-border-radius, var(--border-radius, 8px))`};

  box-shadow: ${({ componentName }) =>
    `var(--${componentName}-box-shadow, none)`};

  &.box-shadow {
    box-shadow: ${({ componentName }) =>
      `var(--${componentName}-box-shadow, 0px 0px 15px 1px #ffffff66 inset,
      4px 12px 26px 0px rgba(0, 0, 0, 0.3))`};
  }

  &:hover {
    background-color: ${({ componentName, isPrimary }) =>
      `var(--${componentName}-hover-background, var(--${componentName}-background ,${
        isPrimary ? "#0a0a0a" : "#e6ebf1"
      }))`};

    color: ${({ componentName, isPrimary }) => {
      if (componentName === "return-button") {
        return `var(--${componentName}-hover-color, var(--${componentName}-color, var(--primary-color, ${
          isPrimary ? "#ffffff" : "#3C3D3D"
        })))`;
      }

      return `var(--${componentName}-hover-color, var(--${componentName}-color, ${
        isPrimary ? "#ffffff" : "#3C3D3D"
      }))`;
    }};

    border: ${({ componentName, isPrimary }) =>
      `var(--${componentName}-hover-border, var(--${componentName}-border,${
        isPrimary ? "1px solid #847d7d" : "1px solid #e6ebf1"
      }))`};
  }
`;

const StyledLoadingSpinner = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isPrimary", "componentName"].includes(prop),
})`
  &:after {
    border-color: transparent;
    border-top-color: ${({ componentName, isPrimary }) =>
      `var(--${componentName}-color, ${isPrimary ? "#ffffff" : "#3C3D3D"})`} !important;
  }

  &:before {
    border-color: ${({ componentName, isPrimary }) =>
      `var(--${componentName}-color, ${isPrimary ? "#ffffff" : "#3C3D3D"})`} !important;
    border-top-color: transparent;
    opacity: 0.1;
  }
`;

interface ButtonComponent {
  className?: string; // Additional class names to apply to the button
  componentName?: string; // Name of the component. Used to retrieve the styles
  text: string; // Text to display on the button
  type?: "button" | "submit" | "reset"; // The type of button
  onClick: Function; // Function to call when the button is clicked
  isPrimary?: boolean; // Whether the button is a primary or secondary button
  isFullWidth?: boolean; // Whether the button should take up the full width of its container
  icon?: React.ComponentType;
  isLoading?: boolean;
  isIconLeft?: boolean; // Whether the icon should be displayed on the left side of the text
  isIconRight?: boolean; // Whether the icon should be displayed on the right side of the text
  hasBoxShadow?: boolean; // Whether the button should have a box shadow
  isExpandable?: boolean; // Whether the button should expand to show text on hover
  isExpanded?: boolean; // Whether the button is currently expanded
  onExpand?: Function; // Function to call when the button is expanded
  expandableText?: string; // Text to display when the button expands
}

const ButtonComponent = ({
  className,
  componentName,
  text,
  type,
  onClick,
  isFullWidth = false,
  isPrimary = true,
  icon,
  isLoading = false,
  isIconLeft = true,
  isIconRight = false,
  hasBoxShadow = false,
  isExpandable = false,
  isExpanded = false,
  onExpand,
  expandableText = "",
}: ButtonComponent) => {
  const buttonClassNames = classNames(
    {
      primary: isPrimary,
      secondary: !isPrimary,
      "full-width": isFullWidth,
      "box-shadow": hasBoxShadow,
      "is-expandable": isExpandable,
      "is-expanded": isExpanded,
      "is-loading": isLoading,
    },
    className,
  );

  if (isIconRight) {
    isIconLeft = false;
  }

  const handleMouseEnter = () => {
    if (isExpandable) {
      onExpand && onExpand(!isExpanded);
    }
  };

  const handleMouseLeave = () => {
    if (isExpandable) {
      onExpand && onExpand(!isExpanded);
    }
  };

  return (
    <StyledButton
      id="button-component"
      className={buttonClassNames}
      componentName={componentName}
      isPrimary={isPrimary}
      type={type}
      disabled={isLoading}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {isLoading && (
        <StyledLoadingSpinner
          componentName={componentName}
          isPrimary={isPrimary}
          className="loading-spinner"
        ></StyledLoadingSpinner>
      )}
      {!isExpanded && isIconLeft && icon && (
        <div className={`icon-left ${isLoading ? "is-loading" : ""}`}>
          {icon}
        </div>
      )}
      <div className={`button-text ${isLoading ? "is-loading" : ""}`}>
        {(isExpanded || !isExpandable) && (text || expandableText)}
      </div>
      {!isExpanded && isIconRight && icon && (
        <div className={`icon-right ${isLoading ? "is-loading" : ""}`}>
          {icon}
        </div>
      )}
    </StyledButton>
  );
};

export default ButtonComponent;
