import { useState, useEffect, useRef } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import HeaderContainer from "container/header";
import InformationScreen from "views/screens/information";
import { useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import formatCurrency from "utils/currency";

// Custom hook
import useIsMobile from "hooks/mobile";
import useNavigateWithQueryParams from "hooks/navigate";

import "./style.less";

const DefaultLayout = () => {
  const contentContainerRef = useRef(null);
  const infoScreenContainerRef = useRef<HTMLDivElement>(null);
  const [leftPosition, setLeftPosition] = useState<number>(0);
  const isMobileView = useIsMobile(1100);

  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { uuid } = useParams();
  const location = useLocation();

  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language,
  );

  // Retrieve data
  const { amount, currency, paymentItems, provider, store } = useAppSelector(
    (state) => state.dataState.data,
  );

  const providerDesignation = provider?.designation?.[currentLanguage];
  const providerLogo = provider?.logo_base64;

  const storeLogo = store?.logo_base64;
  const storeDesignation = store?.designation?.[currentLanguage];

  const { t } = useTranslation("views/screens/information");

  // Show information screen only if payment-items are available
  const hasInformationScreen = paymentItems?.length > 0;

  useEffect(() => {
    if (isMobileView) return;

    const updateFixedPosition = () => {
      if (
        hasInformationScreen &&
        infoScreenContainerRef.current &&
        contentContainerRef.current
      ) {
        const infoScreenWidth =
          infoScreenContainerRef.current.offsetWidth +
          infoScreenContainerRef.current.offsetLeft;
        setLeftPosition(infoScreenWidth); // Set the left position based on the width of the information screen
      } else {
        // Calculate the center position
        const parentWidth = window.innerWidth;
        const contentWidth = contentContainerRef.current.offsetWidth;
        const centerPosition = (parentWidth - contentWidth) / 2;

        setLeftPosition(centerPosition);
      }
    };

    window.addEventListener("resize", updateFixedPosition);
    updateFixedPosition(); // Initial calculation on mount

    return () => {
      window.removeEventListener("resize", updateFixedPosition);
    };
  }, [isMobileView, hasInformationScreen]);

  useEffect(() => {
    if (isMobileView) return;

    const handleScroll = (event) => {
      const rootElement = document.querySelector("#root");
      const currentScrollTop = rootElement.scrollTop;
      const scrollAmount = event.deltaY;

      rootElement?.scrollTo({
        top: currentScrollTop + scrollAmount,
      });

      event.preventDefault();
    };

    const contentContainer = contentContainerRef.current;
    contentContainer.addEventListener("wheel", handleScroll);

    return () => {
      contentContainer.removeEventListener("wheel", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  const openPopup = (url: string) => {
    const width = 800;
    const height = 650;

    // Get the position of the current window (helps with dual-screen setups)
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    // Get the screen dimensions (account for different methods in different browsers)
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      (window.screen && window.screen.width);
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      (window.screen && window.screen.height);

    // Calculate system zoom (needed for correct sizing and positioning)
    const systemZoom =
      screenWidth / (window.screen && window.screen.availWidth);

    // Calculate the center positions, adjusted for system zoom
    const left = (screenWidth - width) / 2 / systemZoom + dualScreenLeft;
    const top = (screenHeight - height) / 2 / systemZoom + dualScreenTop;

    // Open the window
    const newWindow = window.open(
      url,
      "popupWindow",
      `
      scrollbars=yes,
      width=${width / systemZoom},
      height=${height / systemZoom},
      top=${top},
      left=${left}
      `,
    );

    // Focus on the new window
    if (window.focus) newWindow.focus();
  };

  /* TODO: Use provider for design purposes */
  return (
    <div id="default-layout">
      <div className="default-layout-container">
        <div className="main-container">
          {isMobileView && (
            <HeaderContainer
              className="header"
              hasInformationScreen={hasInformationScreen}
            />
          )}

          {hasInformationScreen && (
            <div
              className="informations-screen-container"
              ref={infoScreenContainerRef}
            >
              <InformationScreen />
            </div>
          )}

          <div
            className={`content ${
              isMobileView || !hasInformationScreen ? "" : "fixed"
            }`}
            ref={contentContainerRef}
            style={{
              left: !isMobileView ? `${leftPosition}px` : "initial",
            }}
          >
            <div
              className={`content-container ${
                hasInformationScreen ? "has-information-screen" : ""
              }`}
            >
              {!isMobileView && <HeaderContainer className="header" />}

              {!hasInformationScreen && (
                <div className="information-container">
                  <div className="logo-container">
                    {store?.logo_base64 ? (
                      <img
                        className="logo"
                        src={storeLogo}
                        alt={storeDesignation}
                      />
                    ) : (
                      providerLogo && (
                        <img
                          className="logo"
                          src={providerLogo}
                          alt={providerDesignation}
                        />
                      )
                    )}
                  </div>

                  <div className="sum-container">
                    <div className="flex flex-column">
                      <div className="sum-label">{t("sum")}</div>
                      <div className="sum">
                        {formatCurrency(
                          amount,
                          currentLanguage,
                          currency?.symbol,
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className={`flex items-center ${
                  hasInformationScreen && "flex-grow-1"
                }`}
              >
                <Outlet />
              </div>

              {location.pathname === `/${uuid}` &&
                !isMobileView &&
                hasInformationScreen && (
                  <div className="security-icon">
                    <a
                      href="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html"
                      title="PCI DSS compliant | usd AG"
                      target="_blank"
                      rel="noreferrer noopener"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default behavior of opening in a new tab
                        openPopup(
                          "https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html",
                        );
                      }}
                    >
                      <img
                        src="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/seal_65.png"
                        alt="PCI DSS compliant | usd AG"
                      />
                    </a>
                  </div>
                )}
            </div>

            {(!hasInformationScreen || isMobileView) && (
              <div className="footer">
                <div className="footer-content">
                  <div className="powered-by">
                    {providerDesignation && (
                      <strong>{providerDesignation}</strong>
                    )}{" "}
                    {t("poweredBy")} REHEG
                  </div>
                  <div className="seperator"></div>
                  <div className="flex">
                    <div
                      className="links"
                      onClick={() =>
                        navigateWithQueryParams(`/${uuid}/terms`, true)
                      }
                    >
                      {t("conditions")}
                    </div>
                    <div
                      className="links"
                      onClick={() =>
                        navigateWithQueryParams(`/${uuid}/privacy`, true)
                      }
                    >
                      {t("privacy")}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="security-icon">
        <a
          href="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html"
          title="PCI DSS compliant | usd AG"
          target="_blank"
          rel="noreferrer noopener"
          onClick={(e) => {
            e.preventDefault(); // Prevent default behavior of opening in a new tab
            openPopup(
              "https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html",
            );
          }}
        >
          <img
            src="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/seal_65.png"
            alt="PCI DSS compliant | usd AG"
          />
        </a>
      </div>
    </div>
  );
};

export default DefaultLayout;
