import { AdyenCheckout, Card } from "@adyen/adyen-web";
import { CoreOptions } from "@adyen/adyen-web/dist/types/core/types";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import formatCurrency from "utils/currency";
import { useTranslation } from "react-i18next";
import { useNavigationType } from "react-router-dom";

import {
  createPayment,
  getPaymentStatus,
} from "store/features/onlinePaymentSlice";
import ButtonComponent from "components/buttons";
import { Status as OnlinePaymentStatus } from "store/types/onlinePayment";
import { ProviderProps } from "store/types/provider";

import "@adyen/adyen-web/styles/adyen.css";
import BankTransferScreen from "views/screens/checkout/bank-transfer";

// Import icons
import CardImage from "assets/icons/card.png";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";

// Custom hook
import useIsMobile from "hooks/mobile";
import useNavigateWithQueryParams from "hooks/navigate";

// Styles
import "./style.less";
import "./adyen.less";

interface CheckoutScreenProps {
  provider: ProviderProps;
}

const CheckoutScreen = ({ provider }: CheckoutScreenProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const navigationType = useNavigationType();

  const { uuid, method } = useParams();

  const dispatch = useAppDispatch();

  const isMobileView = useIsMobile(1100);

  const onlinePaymentStatus = useAppSelector(
    (state) => state.onlinePaymentState.onlinePayment.status,
  );
  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language,
  );
  const currentCountry = useAppSelector(
    (state) => state.generalState.general.country,
  );

  const { t } = useTranslation("views/screens/checkout/credit-card");

  // Retrieve data
  const {
    amount,
    paymentItems,
    currency,
    returnUrl,
    tokenize,
    store,
    metadata,
    application,
    hideCvc,
  } = useAppSelector((state) => state.dataState.data);

  const [checkoutInstance, setCheckoutInstance] = useState(null);

  const creditCardComponent = useRef(null);
  const hasInformationScreen = paymentItems?.length > 0;

  const paymentMethods = useAppSelector(
    (state) => state.onlinePaymentState.onlinePayment.paymentMethods,
  );

  useEffect(() => {
    // Scroll to the top of the screen when the component is mounted on mobile
    if (isMobileView) {
      const content = document.getElementById("checkout-screen");

      content?.scrollIntoView({ block: "end" });
    }
    // eslint-disable-next-line
  }, []);

  const paymentButtonText =
    tokenize && amount === 0
      ? t("tokenize")
      : t("pay", {
          amount: formatCurrency(amount, currentLanguage, currency?.symbol),
        });

  async function handleOnSubmit(state: any, component: any, actions: any) {
    setIsLoading(true);
    // Check if the "Save for later" checkbox is checked
    let isStorePaymentMethod = state?.data?.storePaymentMethod || false;
    if (tokenize === true && amount === 0) {
      isStorePaymentMethod = true;
    }

    // Check whether payment is still pending
    const paymentStatusAction = await dispatch(
      getPaymentStatus({ uuid: uuid }),
    );

    const { payload } = paymentStatusAction;

    if (payload?.status !== "pending") {
      navigate("/invalid");
      setIsLoading(false);
      return;
    }

    if (state.isValid) {
      const response = await dispatch(
        createPayment({
          data: state.data.paymentMethod,
          storeId: store?.reference_code,
          amount: amount,
          currencyCode: currency.code,
          currentLanguage: currentLanguage,
          uuid: uuid,
          returnUrl: returnUrl,
          tokenize: isStorePaymentMethod,
          metadata: metadata,
          application: application,
        }),
      );

      if (!response?.payload?.result) {
        setIsLoading(false);
        actions.reject();
      }

      const resultCodeMapping: Record<string, string> = {
        authorized: "Authorized",
        refused: "Refused",
        cancelled: "Cancelled",
        error: "Error",
        redirect: "RedirectShopper",
        identify: "IdentifyShopper",
        challenge: "ChallengeShopper",
        partiallyAuthorized: "PartiallyAuthorized",
      };

      const { action, result } = response.payload;

      const resultCode = resultCodeMapping[result];

      actions.resolve({ action, resultCode });
      setIsLoading(false);
    }
  }

  function handleOnAdditionalDetails(state: any, component: any) {
    // TODO: This function is automatically called when the native approach for 3D secure is implemn
  }

  useEffect(() => {
    const languageMapping = {
      de: "de_DE",
      en: "en_US",
      it: "it_IT",
    };

    const configuration: CoreOptions = {
      environment: process.env.REACT_APP_ADYEN_ENVIRONMENT,
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      locale: languageMapping[currentLanguage],
      countryCode: currentCountry || "DE",
      analytics: {
        enabled: false,
      },
      paymentMethodsResponse: {
        paymentMethods: paymentMethods,
      },
      amount: {
        // TODO // Optional. Displays the amount on the Pay button. ?
        value: amount ? parseInt((amount * 100).toFixed(0)) : 0,
        currency: currency.code, // TODO: set correct value,
      },
      showPayButton: false,
      onSubmit: handleOnSubmit,
      onAdditionalDetails: handleOnAdditionalDetails,
      onPaymentCompleted(result: any) {
        navigateWithQueryParams(`/${uuid}/status/authorized`);
      },
      onPaymentFailed(result: any) {
        navigateWithQueryParams(`/${uuid}/status/refused`);
      },
    };

    async function initiatePayment() {
      if (checkoutInstance) {
        checkoutInstance.unmount();
      }

      const cardConfiguration = {
        enableStoreDetails: amount !== 0, // Show "Save for later" checkbox when amount is 0.0€
        hasHolderName: true,
        holderNameRequired: true,
        hideCVC: hideCvc,
      };

      const checkout = await AdyenCheckout(configuration);
      if (checkout && creditCardComponent.current) {
        const card = new Card(checkout, cardConfiguration);
        card.mount(creditCardComponent.current);
        setCheckoutInstance(card);

        if (tokenize) {
          // Automatically check the "Save for later" checkbox
          setTimeout(() => {
            const storeDetailsCheckbox = document.querySelector(
              "input[name='storeDetails']",
            );
            if (storeDetailsCheckbox) {
              storeDetailsCheckbox.checked = true;

              // Dispatch a change event to update Adyen's internal state
              storeDetailsCheckbox.dispatchEvent(
                new Event("change", { bubbles: true }),
              );
            }
          }, 1000);
        }
      }
    }

    if (paymentMethods.length > 0) {
      initiatePayment().then();
    }
    // eslint-disable-next-line
  }, [
    paymentMethods,
    currentLanguage,
    amount,
    currency.code,
    currency?.symbol,
    tokenize,
  ]);

  let container;
  if (onlinePaymentStatus === OnlinePaymentStatus.Loading) {
    container = (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
      </div>
    ); // TODO
  } else if (
    onlinePaymentStatus === OnlinePaymentStatus.Succeeded ||
    onlinePaymentStatus === OnlinePaymentStatus.Paying
  ) {
    if (uuid) {
      container = (
        <div className="payment-method-credit-card" ref={creditCardComponent} />
      );
    } else {
      navigate("/invalid");
    }
  } else if (onlinePaymentStatus === OnlinePaymentStatus.Failed) {
    navigate("/invalid");
  }

  useEffect(() => {
    const checkPaymentStatus = async () => {
      // Check whether payment is still pending
      const paymentStatusAction = await dispatch(
        getPaymentStatus({ uuid: uuid }),
      );

      const { payload } = paymentStatusAction;

      if (payload?.status !== "pending") {
        navigate("/invalid");
        setIsLoading(false);
        return;
      }
    };

    if (navigationType === "POP") {
      // Check payment status when user navigated back to this screen
      checkPaymentStatus();
    }
  }, [navigationType]);

  return (
    <div id="checkout-screen">
      <div className="checkout-content">
        {method === "bank-transfer" && (
          <BankTransferScreen provider={provider} />
        )}

        {method === "cards" && (
          <>
            <div className="card-container">
              <img
                className={`card ${
                  hasInformationScreen ? "--has-information-screen" : ""
                }`}
                src={CardImage}
                alt="Card"
              />
            </div>

            <>{container}</>
            <ButtonComponent
              className={`adyen-pay-now-button`}
              componentName="pay-button"
              onClick={checkoutInstance?.submit}
              icon={<LockIcon />}
              text={paymentButtonText}
              isPrimary={true}
              isFullWidth={true}
              isLoading={isLoading}
              hasBoxShadow={true}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutScreen;
