import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "store";
import formatCurrency from "utils/currency";
import useIsMobile from "hooks/mobile";
import ButtonComponent from "components/buttons";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as DropdownIcon } from "assets/icons/dropdown.svg";
import { ReactComponent as DateIcon } from "assets/icons/date.svg";
import "./style.less";

// Custom hooks
import useNavigateWithQueryParams from "hooks/navigate";

const InformationScreen = () => {
  const { t } = useTranslation("views/screens/information");
  const location = useLocation();
  const { uuid } = useParams();
  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language
  );
  const data = useAppSelector((state) => state.dataState.data);
  const { paymentItems, currency, amount, store } = data;
  const providerDesignation = data?.provider?.designation?.[currentLanguage];
  const providerLogo = data?.provider?.logo_base64;
  const isMobileView = useIsMobile(750);
  const showTravelInsuranceButton = false;
  const isExpandable =
    (isMobileView && paymentItems?.length >= 2) ||
    (!isMobileView && paymentItems?.length >= 3);
  const [isExpanded, setIsExpanded] = useState(!isMobileView);
  const contentRef = useRef(null);
  const firstItemRef = useRef(null);

  const navigateWithQueryParams = useNavigateWithQueryParams();

  // Set the height of the container (needed for transition effect)
  useEffect(() => {
    if (contentRef.current) {
      if (isExpanded) {
        contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.height = `auto`;
        requestAnimationFrame(() => {
          contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
        });
      }
    }
  }, [isExpanded]);

  // Set the max height of items with "is-not-expanded"
  useEffect(() => {
    if (firstItemRef.current) {
      const firstItemHeight = firstItemRef.current.offsetHeight;
      if (isExpanded) {
        document.querySelectorAll(".is-expanded").forEach((item) => {
          item.style.maxHeight = `none`;
        });
      } else {
        document.querySelectorAll(".is-not-expanded").forEach((item) => {
          item.style.maxHeight = `${firstItemHeight}px`;
        });
      }
    }
  }, [paymentItems, isExpanded]); // Dependency array to reapply on data change

  useEffect(() => {
    // Minimize the room list on route change
    if (isMobileView || (!isMobileView && paymentItems?.length >= 3)) {
      setIsExpanded(false);
    }
  }, [location, isMobileView, paymentItems?.length]);

  return (
    <div id="information-screen">
      <div className="container">
        <div className="flex flex-column">
          <div className="container-header">
            <div className="store-info">
              <div className="logo-container">
                {store?.logo_base64 ? (
                  <img
                    className="logo"
                    src={store?.logo_base64}
                    alt={store?.designation}
                  />
                ) : (
                  providerLogo && (
                    <img
                      className="logo"
                      src={providerLogo}
                      alt={providerDesignation}
                    />
                  )
                )}
              </div>
            </div>
            {isExpandable && (
              <div
                className="dropdown"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {!isMobileView && <div className="text">{t("open")}</div>}
                <DropdownIcon
                  className={`dropdown-icon ${isExpanded ? "is-expanded" : ""}`}
                />
              </div>
            )}
          </div>

          <div className="information-content" ref={contentRef}>
            {paymentItems?.map((item, index) => {
              const title = item?.title?.[currentLanguage];
              const description = item?.description?.[currentLanguage];
              const hasDatePeriod = item?.period?.start && item?.period?.end;

              return (
                <div
                  key={index}
                  onClick={() => setIsExpanded(true)}
                  className={`box ${
                    isExpandable
                      ? isExpanded
                        ? "is-expanded"
                        : "is-not-expanded"
                      : ""
                  }`}
                  ref={index === 0 ? firstItemRef : null} // Assign ref to the first item
                >
                  <div className="box-content">
                    {item?.image_base64 && !isMobileView && (
                      <img
                        className="image"
                        src={item?.image_base64}
                        alt={title}
                      />
                    )}
                    <div className="content-container">
                      <div>
                        <div className="box-header">
                          <div className="title">{title}</div>
                          <div className="price">
                            {formatCurrency(
                              item?.amount,
                              currentLanguage,
                              currency?.symbol
                            )}
                          </div>
                        </div>
                        {!isMobileView ? (
                          <div className="description">{description}</div>
                        ) : (
                          <div className="description-container">
                            {item?.image_base64 && (
                              <img
                                className="image"
                                src={item?.image_base64}
                                alt={title}
                              />
                            )}
                            <div className="description">{description}</div>
                          </div>
                        )}
                      </div>

                      {item?.period && (
                        <div className="date">
                          <DateIcon
                            className={`date-icon ${
                              !isExpanded ? "black" : ""
                            }`}
                          />
                          {!hasDatePeriod && item?.period?.start}
                          {hasDatePeriod &&
                            item?.period?.start + " - " + item?.period?.end}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            {((isExpandable && isExpanded) || !isExpandable) && (
              <div>
                <div className="seperator" />

                {showTravelInsuranceButton && (
                  <ButtonComponent
                    className="add-button"
                    icon={<PlusIcon />}
                    text={t("addTravelInsurance")}
                    isFullWidth={true}
                  />
                )}
              </div>
            )}

            <div className="sum-container">
              <div className="flex flex-column">
                <div className="sum-label">{t("sum")}</div>
                <div className="sum">
                  {formatCurrency(amount, currentLanguage, currency?.symbol)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="powered-by">
            {providerDesignation && <strong>{providerDesignation}</strong>}{" "}
            {t("poweredBy")} REHEG
          </div>
          <div className="seperator"></div>
          <div className="flex">
            <div
              className="links"
              onClick={() => navigateWithQueryParams(`/${uuid}/terms`, true)}
            >
              {t("conditions")}
            </div>
            <div
              className="links"
              onClick={() => navigateWithQueryParams(`/${uuid}/privacy`, true)}
            >
              {t("privacy")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationScreen;
