import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ProviderProps } from "store/types/provider";
import { useAppDispatch, useAppSelector } from "store";
import { CoreOptions } from "@adyen/adyen-web/dist/types/core/types";
import {
  AdyenCheckout,
  GooglePay,
  ApplePay,
  PayByBank,
} from "@adyen/adyen-web";

import {
  createPayment,
  getPaymentStatus,
} from "store/features/onlinePaymentSlice";

// Components
import ButtonComponent from "components/buttons";
import SeperatorComponent from "components/seperator";

// Custom hooks
import useNavigateWithQueryParams from "hooks/navigate";

// Import icons
import { ReactComponent as BankTransferIcon } from "assets/icons/bank-transfer.svg";
import { ReactComponent as CreditCardIcon } from "assets/icons/credit-card.svg";
import { ReactComponent as PayPalIcon } from "assets/icons/paypal.svg";

// Custom hook
import useIsMobile from "hooks/mobile";

import "./style.less";

interface PaymentScreenProps {
  provider: ProviderProps;
}

const PaymentScreen = ({ provider }: PaymentScreenProps) => {
  const isMobileView = useIsMobile(1100);
  const [googlePayInstance, setGooglePayInstance] = useState(null);
  const [applePayInstance, setApplePayInstance] = useState(null);
  const [isApplePayError, setIsApplePayError] = useState(false);
  const [payByBankInstance, setPayByBankInstance] = useState(null);
  const [isPayByBankLoading, setIsPayByBankLoading] = useState(false);
  const googlePayComponent = useRef(null);
  const applePayComponent = useRef(null);
  const payByBankComponent = useRef(null);

  const navigateWithQueryParams = useNavigateWithQueryParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation("views/screens/payment");
  const { uuid } = useParams();

  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language,
  );

  const currentCountry = useAppSelector(
    (state) => state.generalState.general.country,
  );

  const adyenPaymentMethods = useAppSelector(
    (state) => state.onlinePaymentState.onlinePayment.paymentMethods,
  );

  // Retrieve data
  const {
    paymentMethods,
    paymentMessages,
    paymentItems,
    amount,
    currency,
    returnUrl,
    tokenize,
    store,
    metadata,
    application,
  } = useAppSelector((state) => state.dataState.data);

  const hasInformationScreen = paymentItems?.length > 0;

  const handleRedirectCreditCard = (uuid: string) => {
    navigateWithQueryParams(`/${uuid}/cards`);
  };

  const handleRedirectBankTransfer = (uuid: string) => {
    navigateWithQueryParams(`/${uuid}/bank-transfer`);
  };

  useEffect(() => {
    if (paymentMethods?.length === 1) {
      if (paymentMethods?.includes("credit_card")) {
        navigateWithQueryParams(`/${uuid}/cards`);
      } else if (paymentMethods?.includes("bank_transfer")) {
        navigateWithQueryParams(`/${uuid}/bank-transfer`);
      }
    }
  }, [paymentMethods, uuid, navigateWithQueryParams]);

  useEffect(() => {
    const languageMapping = {
      de: "de_DE",
      en: "en_US",
      it: "it_IT",
    };

    const configuration: CoreOptions = {
      environment: process.env.REACT_APP_ADYEN_ENVIRONMENT,
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      locale: languageMapping[currentLanguage],
      countryCode: currentCountry || "DE",
      analytics: {
        enabled: false,
      },
      paymentMethodsResponse: {
        paymentMethods: adyenPaymentMethods,
      },
      amount: {
        value: amount ? parseInt((amount * 100).toFixed(0)) : 0,
        currency: currency.code,
      },
      showPayButton: true,
      onSubmit: handleOnSubmit,
      onPaymentCompleted(result: any) {
        navigateWithQueryParams(`/${uuid}/status/authorized`);
      },
      onPaymentFailed(result: any) {
        navigateWithQueryParams(`/${uuid}/status/refused`);
      },
    };

    const getCSSVar = (name: string) => {
      const styles = getComputedStyle(document.documentElement);

      return styles.getPropertyValue(name);
    };

    async function initiatePayment() {
      let checkout = await AdyenCheckout(configuration);

      // Initialize Google Pay Component
      if (checkout && googlePayComponent.current) {
        const googlePayConfiguration = {
          buttonColor: getCSSVar("--google-pay-button-color") || "white",
          buttonType: getCSSVar("--google-pay-button-type") || "pay",
        };

        if (googlePayInstance) {
          // Unmount previous google pay instance
          googlePayInstance.unmount();
        }

        const googlePay = new GooglePay(checkout, googlePayConfiguration);
        googlePay.mount(googlePayComponent.current);
        setGooglePayInstance(googlePay);
      }

      // Initialize Apple Pay Component
      if (checkout && applePayComponent.current) {
        const applePayConfiguration = {
          buttonColor: getCSSVar("--apple-pay-button-color") || "black",
          buttonType: getCSSVar("--apple-pay-button-type") || "buy",
        };

        if (applePayInstance) {
          // Unmount previous instance
          applePayInstance.unmount();
        }

        const applePay = new ApplePay(checkout, applePayConfiguration);

        applePay
          .isAvailable()
          .then(() => {
            applePay.mount(applePayComponent.current);
          })
          .catch((e) => {
            setIsApplePayError(true);
          });

        setApplePayInstance(applePay);
      }

      // Initialize PayByBank
      if (checkout && payByBankComponent.current) {
        const payByBankConfiguration = {};

        if (payByBankInstance) {
          // Unmount previous instance
          payByBankInstance.unmount();
        }
        // Hide adyen button since we display a custom button
        configuration.showPayButton = false;
        checkout = await AdyenCheckout(configuration);

        const payByBank = new PayByBank(checkout, payByBankConfiguration);
        payByBank.mount(payByBankComponent.current);
        setPayByBankInstance(payByBank);
      }
    }

    if (adyenPaymentMethods.length > 0) {
      initiatePayment().then();
    }
    // eslint-disable-next-line
  }, [
    adyenPaymentMethods,
    currentLanguage,
    amount,
    currency.code,
    currency?.symbol,
    tokenize,
  ]);

  async function handleOnSubmit(state: any, component: any, actions: any) {
    const isPayByBank = state?.data?.paymentMethod?.type === "paybybank";
    if (isPayByBank) {
      setIsPayByBankLoading(true);
    }

    let isStorePaymentMethod = state?.data?.storePaymentMethod || false;
    if (tokenize === true && amount === 0) {
      isStorePaymentMethod = true;
    }

    // Check whether payment is still pending
    const paymentStatusAction = await dispatch(
      getPaymentStatus({ uuid: uuid }),
    );

    const { payload } = paymentStatusAction;

    if (payload?.status !== "pending") {
      if (isPayByBank) {
        setIsPayByBankLoading(false);
      }

      navigate("/invalid");
      return;
    }

    if (state.isValid) {
      const response = await dispatch(
        createPayment({
          data: state.data.paymentMethod,
          storeId: store?.reference_code,
          amount: amount,
          currencyCode: currency.code,
          currentLanguage: currentLanguage,
          countryCode: currentCountry || "DE",
          uuid: uuid,
          returnUrl: returnUrl,
          tokenize: isStorePaymentMethod,
          metadata: metadata,
          application: application,
        }),
      );

      if (!response?.payload?.result) {
        if (isPayByBank) {
          setIsPayByBankLoading(false);
        }

        actions.reject();
      }

      const resultCodeMapping: Record<string, string> = {
        authorized: "Authorized",
        refused: "Refused",
        cancelled: "Cancelled",
        error: "Error",
        redirect: "RedirectShopper",
        identify: "IdentifyShopper",
        challenge: "ChallengeShopper",
        partiallyAuthorized: "PartiallyAuthorized",
      };

      const { action, result } = response.payload;

      const resultCode = resultCodeMapping[result];

      actions.resolve({ action, resultCode });

      if (isPayByBank) {
        setIsPayByBankLoading(false);
      }
    }
  }

  const messages = paymentMessages.find((obj) =>
    obj.hasOwnProperty("selection"),
  );
  let title = messages?.selection?.title?.[currentLanguage];
  let description = messages?.selection?.description?.[currentLanguage];

  return (
    <div
      id="payment-screen"
      className={hasInformationScreen ? "--has-information-screen" : ""}
    >
      <div className="info">
        <h1 className="title">{title}</h1>

        <p className="text">{description}</p>

        <div className="payment-methods-container">
          {paymentMethods?.includes("bank_transfer") && (
            <ButtonComponent
              className="payment-method bank-transfer"
              componentName="bank-transfer-button"
              text={t("bankTransfer")}
              icon={<BankTransferIcon />}
              isPrimary={false}
              isFullWidth={true}
              onClick={() => handleRedirectBankTransfer(uuid)}
            />
          )}

          {paymentMethods?.includes("pay_by_bank") && (
            <>
              <div ref={payByBankComponent}></div>
              <ButtonComponent
                className="payment-method bank-transfer"
                componentName="bank-transfer-button"
                onClick={payByBankInstance?.submit}
                text={t("bankTransfer")}
                icon={<BankTransferIcon />}
                isPrimary={false}
                isLoading={isPayByBankLoading}
                isFullWidth={true}
              />
            </>
          )}

          {paymentMethods?.includes("credit_card") && (
            <ButtonComponent
              className="payment-method"
              componentName="credit-card-button"
              text={t("creditCard")}
              icon={<CreditCardIcon />}
              isFullWidth={true}
              onClick={() => handleRedirectCreditCard(uuid)}
            />
          )}

          {(paymentMethods?.includes("paypal") ||
            (paymentMethods?.includes("apple_pay") && !isApplePayError) ||
            paymentMethods?.includes("google_pay")) &&
            (paymentMethods?.includes("credit_card") ||
              paymentMethods?.includes("bank_transfer")) && (
              <div className="seperator-container">
                <SeperatorComponent text={t("or")} />
              </div>
            )}

          {paymentMethods?.includes("paypal") && (
            <ButtonComponent
              className="payment-method paypal"
              componentName="paypal-button"
              icon={<PayPalIcon />}
              isFullWidth={true}
            />
          )}

          <div
            className={`flex ${isMobileView ? "flex-wrap gap-md" : "gap-md"}`}
          >
            {paymentMethods?.includes("apple_pay") && (
              <div
                id="applepay-container"
                className={isApplePayError ? "error" : ""}
                ref={applePayComponent}
              ></div>
            )}

            {paymentMethods?.includes("google_pay") && (
              <div id="googlepay-container" ref={googlePayComponent}></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentScreen;
