export enum AvailableLanguages {
  // noinspection JSUnusedGlobalSymbols
  German  = "de",
  Italian = "it",
  English = "en",
}

export interface GeneralProps {
  // @formatter:off
  language: AvailableLanguages
  accessToken: string
  country: string
  uuid: string
  // @formatter:on
}
