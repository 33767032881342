import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";

// Components
import LanguageSelectComponent from "components/language-select";

// Custom hook
import useNavigateWithQueryParams from "hooks/navigate";

import "./style.less";

const PrivacyScreen = () => {
  const { t } = useTranslation("views/screens/privacy");
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { uuid } = useParams();

  // Retrieve data
  const { provider } = useAppSelector((state) => state.dataState.data);

  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language,
  );

  const providerDesignation = provider?.designation?.[currentLanguage];

  const openPopup = (url: string) => {
    const width = 800;
    const height = 650;

    // Get the position of the current window (helps with dual-screen setups)
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    // Get the screen dimensions (account for different methods in different browsers)
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      (window.screen && window.screen.width);
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      (window.screen && window.screen.height);

    // Calculate system zoom (needed for correct sizing and positioning)
    const systemZoom =
      screenWidth / (window.screen && window.screen.availWidth);

    // Calculate the center positions, adjusted for system zoom
    const left = (screenWidth - width) / 2 / systemZoom + dualScreenLeft;
    const top = (screenHeight - height) / 2 / systemZoom + dualScreenTop;

    // Open the window
    const newWindow = window.open(
      url,
      "popupWindow",
      `
      scrollbars=yes,
      width=${width / systemZoom},
      height=${height / systemZoom},
      top=${top},
      left=${left}
      `,
    );

    // Focus on the new window
    if (window.focus) newWindow.focus();
  };

  return (
    <div id="privacy-screen">
      <div className="privacy-screen-container">
        <div className="main-container">
          <div className="container">
            <div className="header-container">
              <LanguageSelectComponent />
            </div>
            <div className="privacy-container">
              <div className="title">{t("title")}</div>

              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: t("text") }}
              />
            </div>

            <div className="footer">
              <div className="footer-content">
                <div className="powered-by">
                  {providerDesignation && (
                    <strong>{providerDesignation}</strong>
                  )}{" "}
                  {t("poweredBy")} REHEG
                </div>
                <div className="seperator"></div>
                <div className="flex">
                  <div
                    className="links"
                    onClick={() =>
                      navigateWithQueryParams(`/${uuid}/terms`, false)
                    }
                  >
                    {t("conditions")}
                  </div>
                  <div className="links active">{t("privacy")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="security-icon">
        <a
          href="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html"
          title="PCI DSS compliant | usd AG"
          target="_blank"
          rel="noreferrer noopener"
          onClick={(e) => {
            e.preventDefault(); // Prevent default behavior of opening in a new tab
            openPopup(
              "https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/details_en.html",
            );
          }}
        >
          <img
            src="https://my-pci.usd.de/compliance/6DFFD11D-6FA9-487E-93A6-E144EE31174F/seal_65.png"
            alt="PCI DSS compliant | usd AG"
          />
        </a>
      </div>
    </div>
  );
};

export default PrivacyScreen;
