import { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { styled } from "styled-components";

// Import icons
import { ReactComponent as ErrorIcon } from "assets/icons/error-field.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/check.svg";

import "./style.less";

const StyledInput = styled.input`
  background-color: var(--input-background, #fff);
  color: var(--input-color, var(--primary-color, #3c3d3d));
  border: var(--input-border, 1px solid #e6ebf1);
  border-radius: var(--input-border-radius, var(--border-radius, 8px));

  &::placeholder {
    color: var(--input-placeholder, #919191);
  }

  &:focus {
    border: var(--input-hover-border, 1px solid #d9dadc);
  }

  &:hover {
    border: var(--input-hover-border, 1px solid #d9dadc);
  }
`;

const StyledLabel = styled.div`
  color: var(--input-label-color, var(--primary-color, #3c3d3d));
`;

const StyledOptionalInfo = styled.div`
  color: var(--input-label-color, var(--primary-color, #3c3d3d));
`;

interface InputComponent {
  className: string; // Additional class names to apply to the button
  id: string; // The id of the input field
  name: string; // The name of the input field
  type: string; // The type of the input field
  label: string; // The label for the input field
  placeholder: string; // The placeholder text for the input field
  isFullWidth: boolean; // Whether the button should take up the full width of its container
  isRequired: boolean; // Whether the input field is required
  isValid: boolean; // Whether the input field is valid
  allowSpecialCharacters: boolean; // Whether the input field should allow special characters
  errorMessage: string; // The error message to display if the input field is invalid
  onBlur?: Function; // The function to call when the input field loses focus
  onChange?: Function; // The function to call when the input field changes
}

const InputComponent = ({
  className,
  id,
  name,
  type = "text",
  label,
  placeholder,
  isFullWidth = false,
  isRequired = false,
  isValid = false,
  allowSpecialCharacters = true,
  errorMessage,
  onBlur,
  onChange,
}: InputComponent) => {
  const [value, setValue] = useState<string>("");

  const { t } = useTranslation("components/input");

  const inputClassNames = classNames(
    {
      "full-width": isFullWidth,
    },
    className,
  );

  // Custom handler to prevent special characters if allowSpecialCharacters is false
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;

    if (!allowSpecialCharacters) {
      value = value.replace(/[^a-zA-Z0-9 ]/g, ""); // Only allow letters, numbers, and spaces
    }

    setValue(value);

    if (onChange) {
      event.target.value = value;

      onChange(event);
    }
  };

  return (
    <div id="input-component" className={inputClassNames}>
      <div className="flex items-center">
        <StyledLabel className="label">{label}</StyledLabel>
        {!isRequired && (
          <StyledOptionalInfo className="optional-info">{`(${t(
            "optional",
          )})`}</StyledOptionalInfo>
        )}
      </div>
      <div className="input-wrapper">
        <StyledInput
          id={id}
          name={name}
          className="input"
          placeholder={placeholder}
          type={type}
          required={isRequired}
          value={value}
          onBlur={onBlur}
          onChange={handleInputChange}
        />
        {errorMessage && <ErrorIcon className="error-icon" />}
        {isValid && <SuccessIcon className="success-icon" />}
      </div>
      <div className="error-message">{errorMessage}</div>
    </div>
  );
};

export default InputComponent;
