import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";


import { getPaymentMethods } from "store/features/onlinePaymentSlice";
import {
  setLanguage,
  setAccessToken,
  setUUID,
  getUserCountry,
} from "store/features/generalSlice";
import { getData } from "store/features/dataSlice";
import { Status } from "store/types/data";

import { UUID } from "store/types/paymentLink";
import { Outlet } from "react-router-dom";

import { applyStyles } from "utils/styles";

// Custom hooks
import useNavigateWithQueryParams from "hooks/navigate";

import LoadingScreen from "./views/screens/loading";

const ApplicationWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { uuid } = useParams();
  const location = useLocation();

  const { status } = useAppSelector((state) => state.dataState.data);

  useEffect(() => {
    const controller = new AbortController();

    // Get access token from query params and save it in store
    const query = new URLSearchParams(window.location.search);
    const accessToken = query.get("access_token");
    dispatch(setAccessToken(accessToken));

    async function handleGetData(uuid: string) {
      const resultAction = await dispatch(
        getData({ uuid: uuid, controller: controller })
      );

      // Check if the request was rejected
      if (getData.rejected.match(resultAction)) {
        navigateWithQueryParams("/invalid");
      } else {
        const { payload } = resultAction;

        if (payload?.status !== "pending") {
          const isTermsOrPrivacy =
            location &&
            (location?.pathname?.includes("terms") ||
              location?.pathname?.includes("privacy"));

          // Terms and privacy screen should always be accessible
          if (!isTermsOrPrivacy) {
            navigateWithQueryParams("/invalid");
          }
        }

        // Check if there are styles in the "store" or "provider" object and apply them
        if (payload?.store?.styles) {
          applyStyles(payload?.store?.styles);
        } else if (payload?.provider?.styles) {
          applyStyles(payload?.provider?.styles);
        }

        // Initialize language when provided
        if (payload?.language) {
          dispatch(setLanguage(payload?.language));
        }

        return payload;
      }
    }

    async function handleGetCountry() {
      const { payload } = await dispatch(getUserCountry({ uuid: uuid, controller: controller }));

      if(!payload?.country) {
        return null;
      }

      return payload.country;
    }

    async function fetchRequiredData() {
      setIsLoading(true);
      try {
        // Get country of user
        await handleGetCountry();

        // Get data
        await handleGetData(uuid);

        // Get adyen payment methods
        await dispatch(getPaymentMethods());
      } catch(e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }

    // Check whether the UUID is valid
    if (!uuid || !uuid.match(UUID)) {
      navigateWithQueryParams("/invalid");
    } else {
      // Load data
      if (status === Status.Idle) {
        dispatch(setUUID(uuid));
        fetchRequiredData();
      }
    }
    return () => {
      controller.abort();
    };
  }, [uuid, dispatch, status]);

  return (
    <>
      {isLoading && <LoadingScreen />}
      {!isLoading && <Outlet />}
    </>
  );
};

export default ApplicationWrapper;
