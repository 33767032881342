import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { setAdditionalPaymentDetails } from "store/features/onlinePaymentSlice";

// Components
import HeaderContainer from "container/header";

// Custom hooks
import useNavigateWithQueryParams from "hooks/navigate";

import "./style.less";

const RedirectScreen = () => {
  const { t } = useTranslation("views/screens/payment/redirect");
  const { uuid } = useParams();

  const dispatch = useAppDispatch();
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language,
  );

  const { provider } = useAppSelector((state) => state.dataState.data);
  const providerDesignation = provider?.designation?.[currentLanguage];

  // Retrieve redirect-result from URL
  const [searchParams] = useSearchParams();
  const redirectResult = searchParams.get("redirectResult");

  useEffect(() => {
    if (!redirectResult) return;

    const sendPaymentDetails = async () => {
      // Send additional payment details to the backend (redirect-result from 3DSecure payment)
      const response = await dispatch(
        setAdditionalPaymentDetails({
          redirectResult: redirectResult,
        }),
      );

      if (response.payload?.result === "authorized") {
        // Payment authorized
        navigateWithQueryParams(`/${uuid}/status/authorized`);
      } else {
        // Payment failed
        navigateWithQueryParams(`/${uuid}/status/refused`);
      }
    };

    sendPaymentDetails();
  }, [redirectResult]);

  return (
    <div id="redirect-screen">
      <HeaderContainer className="header" />

      <div className="redirect-screen-container">
        <div className="content">
          <div className="loading-spinner"></div>
          <div className="title">{t("title")}</div>
          <div className="description">{t("description")}</div>
        </div>
      </div>

      <div className="footer-container">
        <div className="footer">
          <div className="footer-content">
            <div className="powered-by">
              {providerDesignation && <strong>{providerDesignation}</strong>}{" "}
              {t("poweredBy")} REHEG
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedirectScreen;
